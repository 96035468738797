<template>
    <div class="userCenter">
        <van-cell-group class="bgNo userCenterInfo">
            <van-cell center label="1年用户" to="/patient/my/editUser">
                <template #icon>
                    <van-image class="thumbnailImg mr10" :src="user.wxHeadimgurl" />
                </template>
                <template #title>
                    <span class="custom-title">{{ user.wxNickname }}</span>
                    <span class="userTag" :style="tagBg">Lv.1</span>
                    <!-- <van-tag size="mini" ></van-tag> -->
                </template>
                <template #right-icon>
                    <van-image class="userSetting mr10" :src="userSetting" />
                </template>
            </van-cell>
        </van-cell-group>
        <div class="userCenterLink">
            <van-cell-group class="userLinkList bgNo">
                <van-cell center title="我的治疗仪" to="/patient/my/equipments">
                    <template #icon>
                        <van-image class="linkThumbnailImg mr10" :src="LinkIcon04" />
                    </template>
                </van-cell>
                <van-cell center title="我的购买记录" to="/patient/my/rechargeRecords">
                    <template #icon>
                        <van-image class="linkThumbnailImg mr10" :src="LinkIcon04" />
                    </template>
                </van-cell>
                <van-cell center title="我的测试报告" to="/patient/my/testReports">
                    <template #icon>
                        <van-image class="linkThumbnailImg mr10" :src="LinkIcon01" />
                    </template>
                </van-cell>
                <van-cell center title="我的疗程记录" to="/patient/my/courseRecords">
                    <template #icon>
                        <van-image class="linkThumbnailImg mr10" :src="LinkIcon03" />
                    </template>
                </van-cell>
                <van-cell center title="我的邀请码" to="/patient/my/invitationCode">
                    <template #icon>
                        <van-image class="linkThumbnailImg mr10" :src="LinkIcon02" />
                    </template>
                </van-cell>
            </van-cell-group>
            <!-- <div class="logout">
                <van-button class="mt40" :style="logoutButton" block  @click="logout">退出</van-button>
            </div> -->
        </div>
    </div>
</template>

<script>
import { getUser } from '@/services/user';
import { toast } from '@/util';

export default {
    // name: "index"
    data() {
        return {
            user: {},
            userImg: [require('@/assets/images/userImg.png')],
            userSetting: require('@/assets/images/shezhi.png'),
            // userTag: require('@/assets/images/crown.png'),
            LinkIcon01: require('@/assets/images/userLinkIcon-01.png'),
            LinkIcon02: require('@/assets/images/userLinkIcon-02.png'),
            LinkIcon03: require('@/assets/images/userLinkIcon-03.png'),
            LinkIcon04: require('@/assets/images/userLinkIcon-04.png'),
            tagBg: {
                backgroundImage: 'url(' + require('@/assets/images/tabBg.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            },
            logoutButton: {
                color:'#4c4c4c',
                boxSizing:'border-box',
                borderRadius: '8px',
                fontSize:'14px',
                background:'#fff',
            },
            sexNan: require('@/assets/images/nan.png'),
            sexNv: require('@/assets/images/nv.png'),
            userPhoto: require('@/assets/images/userPhoto.png'),
            userDefault: require('@/assets/images/userDefault.png'),
        };
    },
    async created() {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');
        if (!userId || !token) {
            return await this.$router.push('/passport/wxAuthLogin');
        }
        await this.getUser();
    },
    methods: {
        async getUser() {
            const { code, message, data } = await getUser();
            if (!code) {
                toast(message);
            }
            this.user = data;
            await this.$store.dispatch('setUserInfo', data)
        },

        logout() {
            localStorage.clear();
            this.$router.push('/passport/wxAuthLogin');
        },
    },
};
</script>

<style scoped>
.userCenterInfo .van-cell {
    background: none;
    padding: 25px;
}

.thumbnailImg {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
}

.custom-title {
    font-size: 14px;
    color: #282a2d;
}

.userTag {
    padding: 2px 0px;
    color: #fff;
    margin-left: 5px;
    font-size: 10px;
    width: 45px;
    display: inline-block;
    height: 15px;
    line-height: 17px;
    text-indent: 19px;
    position: relative;
    top: -2px;
}

.userSetting {
    width: 30px;
    height: 30px;
}

.userLinkList {
    background: none;
    margin: 5px;
}

.userLinkList .van-cell {
    border-radius: 8px;
    margin-bottom: 5px;
}

.linkThumbnailImg {
    width: 30px;
    height: 30px;
}

.userLinkList .van-cell__title {
    font-size: 13px;
    color: #4c4c4c;
}

.userCenter {
    height: 100%;
}

.userCenterLink {
    background: #f6f6f8;
    padding: 5px 0;
    height: calc(100% - 110px);
}

.instrumentCard {
    background: #fff;
    margin: 0 5px;
    overflow: hidden;
    border-radius: 8px;
}

.cardTile {
    box-sizing: border-box;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}

.cardTile::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background: #4ab8ab;
    width: 90%;
    left: 5%;
    bottom: 0;
}

.myBound.cardTile::after {
    background: #707070;
}

.cardTileLeft {
    position: relative;
}

.cardTileLeft::before {
    content: '';
    display: block;
    position: absolute;
    left: -16px;
    top: 3px;
    width: 5px;
    height: 20px;
    background: #4ab8ab;
}

.cardTileRight {
    font-size: 14px;
    font-weight: 600;
    color: #4ab8ab;
}

.cardBody .cardBodyLeft {
    font-size: 24px;
    color: #8d8d8d;
    font-weight: 600;
}

.cardBody .cardBodyRight {
    font-size: 24px;
    color: #282a2d;
    text-align: left;
    text-indent: 20px;
}

.noInstrument {
    font-size: 12px;
    padding: 15px 16px;
    color: #8d8d8d;
    font-weight: 600;
}

.userInfo .van-cell-group {
    margin: 5px;
}

.userInfo .van-cell__left-icon,
.userInfo .van-icon__image {
    width: 48px;
    height: 48px;
}

.userInfo .van-image {
    width: 24px;
    height: 24px;
}
.userCenterLink .logout{
    padding: 0 5px;
}
.van-button::before{
    display: none;
}
</style>
